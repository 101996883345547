var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v9TjU6EXTmmpsTP1KbZAt"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import packageJson from './package.json';
import SentryError from 'types/sentryError';
import { AxiosError } from 'axios';

const isSentryEnable = process.env.ENABLE_SENTRY === 'on';

if (isSentryEnable) {
  Sentry.init({
    beforeSend(event, hint) {
      const error = hint.originalException;
      const currentPath = window.location.pathname;
      if (error && error instanceof SentryError) {
        const manipulationError = {
          url: (error.cause as AxiosError).config?.url,

          request: (error.cause as AxiosError).config?.data,
          response: (error.cause as AxiosError).response?.data,
        };
        event.fingerprint = [
          'api-error',
          currentPath,
          error.name.split(` ${currentPath}`).join('').toLowerCase().replaceAll(' ', '-'),
        ];
        event.message = JSON.stringify(manipulationError);
      } else {
        event.fingerprint = ['error-code'];
      }

      return event;
    },
    environment: process.env.APP_CONFIG_ENV,
    dsn: process.env.SENTRY_DSN,
    // Replay may only be enabled for the client-side
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    release: packageJson.version,
  });
}
